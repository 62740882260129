import React, { Fragment, useEffect, useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import Avatar from 'react-avatar'
import { ReactComponent as StarIcon } from '../../assets/img/icons/icon-star.svg';
import { ReactComponent as UserIcon } from '../../assets/img/icons/icon-user.svg';
import { ReactComponent as TrashIcon } from "../../assets/img/icons/icon-trash.svg";
import { ReactComponent as InboxIcon } from "../../assets/img/icons/letter-icon.svg";

import css from "./ChatRecruiterHeader.module.scss";
import PropTypes from "prop-types";
import {AvatarProfile, DropdownAvatarSelect, RoundButton} from "../../index";

const ChatRecruiterHeader = ({ candidate, isFavorite, recruiterAssigned, recruiterList, showReassignFeature, onFavoriteClick, onAssignedRecruiter, onClick, archived = false, currRecruiter  }) => {
  const size = 25;
  const {id, name, photo, profession, specialties, is_anonymous, completion_percentage} = candidate;
  const [ hideTooltip, setHideTooltip ] = useState(false);

  return (
    <div className={`${css["chat-header"]}`}>
      <div className={css['recruiter-image']}>
        <AvatarProfile
          image={photo}
          name={name}
          percentage={completion_percentage}
          size={20}
          hideUserAvatar={is_anonymous}
          isAnonymous={is_anonymous}
          hideBadge={true}
          classname={css['avatar-spacing']}
        />
      </div>

      <div className={css['recruiter-info']}>
        <div className={css['recruiter-info-name']}> {!is_anonymous ? name : <div>&nbsp;</div> } </div>

        <div className={css['recruiter-info-reviews']}>
          {
            +specialties.length > 0 && <ReactTooltip
              arrowColor="#A2A5AC"
              backgroundColor="#A2A5AC"
              borderColor="#A2A5AC"
              className={`rc-tooltip ${css['rc-tooltip']}`}
              effect="solid"
              id={`specialties-${id}`}
              multiline
              place="top"
            >
              {specialties.join(', ')}
            </ReactTooltip>
          }

          <div className={css['rating-reviews']}>
            <div className={css['rating-reviews-profession']}>{profession}</div>
            <div className={css['rating-reviews-specialties']} data-for={`specialties-${id}`} data-tip >
              { specialties?.length > 0 ? <>· {specialties.slice(0, 3).join(' · ')}</> : <div>&nbsp;</div> }
            </div>
          </div>
        </div>
      </div>

      <div className={css['buttons-container']}>
        <div className={css['delete-container']}>
          {archived ? (
            <>
              <button onClick={onClick}>
                <InboxIcon fill="#A09F9D" style={{marginRight: '4px'}}/> Inbox
              </button>
            </>
            ) :
            (
              <>
                <button onClick={onClick}>
                  <TrashIcon fill="#A09F9D" style={{marginRight: '4px'}}/> Archive
                </button>
              </>
            )
          }
        </div>
        <ReactTooltip
          arrowColor="#7B7B7C"
          backgroundColor="#7B7B7C"
          borderColor="#7B7B7C"
          className={`rc-tooltip ${css['rc-tooltip']}`}
          effect="solid"
          id="button-favorite-tooltip"
          multiline
          place="left"
        >
          {isFavorite ? 'Favorited' : 'Add to Favorites'}
        </ReactTooltip>
        <RoundButton
          classnames={ `${css['round-button']} ${isFavorite
            ? css['round-button-yellow']
            : css['round-button-gray']}` }
          onClick={onFavoriteClick}
        >
          <div data-for="button-favorite-tooltip" data-tip>
            {isFavorite ? (
              <StarIcon className={''}  fill="#f7c132"/>
            ) : (
              <StarIcon className={''} fill="#A2A5AC" />
            )}
          </div>
        </RoundButton>

        {showReassignFeature && (
          <>
            {!hideTooltip && (
              <ReactTooltip
                arrowColor="#7B7B7C"
                backgroundColor="#7B7B7C"
                borderColor="#7B7B7C"
                className={`rc-tooltip ${css['rc-tooltip']}`}
                effect="solid"
                id="button-recruiter-assigned"
                multiline
                place="left"
              >
                Owner: {recruiterAssigned?.name}
              </ReactTooltip>
            )}
            <div
              className={css['dropdown-avatar']}
              data-for="button-recruiter-assigned"
              data-tip
              onMouseOver={() => setHideTooltip(false)}
            >
              <DropdownAvatarSelect
                getOptionValue={(option) => option.name}
                icon={<UserIcon />}
                isMulti={false}
                onChange={(e) => {
                  onAssignedRecruiter(e);
                  setHideTooltip(true);
                }}
                options={recruiterList}
                placeholder=""
                value={recruiterAssigned}
                sizeAvatar={size}
                isDisabled={currRecruiter?.id !== recruiterAssigned?.id}
                showNameTooltip={false}
              />
            </div>
          </>
        )}

      </div>
    </div>
  );
};

ChatRecruiterHeader.propTypes = {
  candidate: PropTypes.object.isRequired,
  recruiterAssigned: PropTypes.object,
  onFavoriteClick:  PropTypes.func.isRequired,
  onAssignedRecruiter: PropTypes.func.isRequired,
};

export default ChatRecruiterHeader;
