import React from "react";
import css from './TagNurse.module.scss'
import PropTypes from "prop-types";

const TagNurse = ({label, type, onClick}) => {
  const handleOnClick = (e) => {
    e.stopPropagation();
    if (onClick) {
      onClick();
    }
  }
  const classes = css[type || 'yellow'];
  return (
    <span className={`${css['tag-nurse']} ${classes}`.trim()} onClick={handleOnClick}>{label}</span>
  );
};

TagNurse.propTypes = {
  type: PropTypes.oneOf(['yellow', 'orange', 'green', 'blue', 'purple']),
  onClick: PropTypes.func,
  label: PropTypes.string,
};

TagNurse.defaultProps = {
  type: 'yellow',
  onClick: () => {},
  label: ''
};

export default TagNurse;
