import React from 'react';

export const DOCUMENT_IDENTIFICATION_NAMES = {
  driver_license: 'Driver License',
  social_security_card: 'Social Security Card',
  passport: 'Passport',
  birth_certificate: 'Birth Certificate',
};

export const JobTypeEnum = Object.freeze({
  TRAVEL: '1',
  PERMANENT: '2',
  INTERNATIONAL: '3',
});

export const PROPOSED_MESSAGE = 'PROPOSED_MESSAGE';

export const POSTED = 1;
export const DRAFT = 2;
export const ARCHIVED = 3;
export const PPOD_REQUESTED = 4;

// Messages Sources from AI Lead
export const CANDIDATE_WEB = 1;
export const RECRUITER_WEB = 2;
export const AI_LEAD_FULL_APPLY = 3;
export const AI_LEAD_QUICK_APPLY = 4;
export const RECRUITER_PROPOSE_JOB = 5; // Propose Job
export const INTERESTED_JOB = 6; // Interested
