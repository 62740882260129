import React from 'react';
import classes from '../JobCard.module.scss'
import ReactTooltip from 'react-tooltip'
import { ReactComponent as IconHospital } from '../../../assets/img/icons/icon-hospital-white.svg'
import { ReactComponent as IconExperience } from '../../../assets/img/icons/icon-experience.svg'
import { ReactComponent as IconBeds } from '../../../assets/img/icons/icon-beds.svg'
import { ReactComponent as IconDay } from '../../../assets/img/icons/icon-day.svg'
import { ReactComponent as IconMid } from '../../../assets/img/icons/icon-mid.svg'
import { ReactComponent as IconNight } from '../../../assets/img/icons/icon-night.svg'

const Permanent = ({ jobData, isNurseApp }) => {
  const ShiftIcon = jobData.shift_icon === 'day' ? IconDay : jobData.shift_icon === 'mid' ? IconMid : IconNight;

  const getExperienceLevel = (expLevel) => {
    const config = {
      1: 'Entry Level',
      2: 'Residency Program',
      3: 'Experienced',
      4: 'Leadership'
    }

    return config[+expLevel] || false;
  }

  return (
    <div className={'row'}>
      {isNurseApp ? (
        <div className={`col-2 ${classes['no-padding']}`}>
          <ReactTooltip
            place={'top'}
            className={`rc-tooltip ${classes['rc-tooltip']}`}
            effect="solid"
            multiline={true}
            id={`job-card-shift-${+jobData.id}`}
            backgroundColor={'#7B7B7C'}
            arrowColor={'#7B7B7C'}
            borderColor={'#7B7B7C'}>
            {jobData.shift_label}
          </ReactTooltip>
          <div className={`${classes['shift']} ${classes[jobData.shift_icon]}`}>
            <ShiftIcon fill={'#DFDFDF'} data-tip data-for={`job-card-shift-${+jobData.id}`}/> <span data-tip data-for={`job-card-shift-${+jobData.id}`}>{jobData.shift_label}</span>
          </div>
        </div>
      ) : (<div className={`col-2 ${classes['no-padding']} ${classes['hospital-padding']}`}>
        <ReactTooltip
          place={'top'}
          className={`rc-tooltip ${classes['rc-tooltip']}`}
          effect="solid"
          multiline={true}
          id={`job-card-shift-${+jobData.id}`}
          backgroundColor={'#7B7B7C'}
          arrowColor={'#7B7B7C'}
          borderColor={'#7B7B7C'}>
          {jobData?.facility?.beds || 0} beds
        </ReactTooltip>
        <div className={`${classes['shift']} ${classes[jobData.shift_icon]}`}>
          <IconBeds fill={'#DFDFDF'} data-tip data-for={`job-card-shift-${+jobData.id}`}/> <span data-tip data-for={`job-card-shift-${+jobData.id}`}>{jobData?.facility?.beds || 0} beds</span>
        </div>
      </div>)}

      {isNurseApp ? ( <div className={`col-2 ${classes['no-padding']}`}>
        <ReactTooltip
          place={'top'}
          className={`rc-tooltip ${classes['rc-tooltip']}`}
          effect="solid"
          multiline={true}
          id={`job-card-duration-${+jobData.id}`}
          backgroundColor={'#7B7B7C'}
          arrowColor={'#7B7B7C'}
          borderColor={'#7B7B7C'}>
          {getExperienceLevel(jobData?.experience_level || 1)}
        </ReactTooltip>
        <div className={classes['duration']}>
          <IconExperience fill={'#DFDFDF'} data-tip data-for={`job-card-duration-${+jobData.id}`} style={{width: '13px'}} /> <span data-tip data-for={`job-card-duration-${+jobData.id}`}>{getExperienceLevel(jobData?.experience_level || 1)}</span>
        </div>
      </div>) : (
        <div className={`col-2 ${classes['no-padding']}`}>
          <ReactTooltip
            place={'top'}
            className={`rc-tooltip ${classes['rc-tooltip']}`}
            effect="solid"
            multiline={true}
            id={`job-card-shift-${+jobData.id}`}
            backgroundColor={'#7B7B7C'}
            arrowColor={'#7B7B7C'}
            borderColor={'#7B7B7C'}>
            {jobData.shift_label}
          </ReactTooltip>
          <div className={`${classes['shift']} ${classes[jobData.shift_icon]}`}>
            <ShiftIcon fill={'#DFDFDF'} data-tip data-for={`job-card-shift-${+jobData.id}`}/> <span data-tip data-for={`job-card-shift-${+jobData.id}`}>{jobData.shift_label}</span>
          </div>
        </div>
      )}

      {isNurseApp ? (<div className={`col-2 ${classes['no-padding']} ${classes['hospital-padding']}`}>
        <ReactTooltip
          place={'top'}
          className={`rc-tooltip ${classes['rc-tooltip']}`}
          effect="solid"
          multiline={true}
          id={`job-card-shift-${+jobData.id}`}
          backgroundColor={'#7B7B7C'}
          arrowColor={'#7B7B7C'}
          borderColor={'#7B7B7C'}>
          {jobData?.facility?.beds || 0} beds
        </ReactTooltip>
        <div className={`${classes['shift']} ${classes[jobData.shift_icon]}`}>
          <IconBeds fill={'#DFDFDF'} data-tip data-for={`job-card-shift-${+jobData.id}`}/> <span data-tip data-for={`job-card-shift-${+jobData.id}`}>{jobData?.facility?.beds || 0} beds</span>
        </div>
      </div>) : (<div className={`col-2 ${classes['no-padding']}`}>
        <ReactTooltip
          place={'top'}
          className={`rc-tooltip ${classes['rc-tooltip']}`}
          effect="solid"
          multiline={true}
          id={`job-card-duration-${+jobData.id}`}
          backgroundColor={'#7B7B7C'}
          arrowColor={'#7B7B7C'}
          borderColor={'#7B7B7C'}>
          {getExperienceLevel(jobData?.experience_level || 1)}
        </ReactTooltip>
        <div className={classes['duration']}>
          <IconExperience fill={'#DFDFDF'} data-tip data-for={`job-card-duration-${+jobData.id}`} style={{width: '13px'}} /> <span data-tip data-for={`job-card-duration-${+jobData.id}`}>{getExperienceLevel(jobData?.experience_level || 1)}</span>
        </div>
      </div>)}

      <div className={`col-6 ${classes['no-padding']}`}>
        <ReactTooltip
          place={'top'}
          className={`rc-tooltip ${classes['rc-tooltip']}`}
          effect="solid"
          multiline={true}
          id={`job-card-hospital-${+jobData.id}`}
          backgroundColor={'#7B7B7C'}
          arrowColor={'#7B7B7C'}
          borderColor={'#7B7B7C'}>
          {jobData?.facility?.name || ''}
        </ReactTooltip>
        <div className={classes['duration']}>
          <IconHospital fill={'#DFDFDF'} data-tip data-for={`job-card-hospital-${+jobData.id}`} /> <span data-tip data-for={`job-card-hospital-${+jobData.id}`}>{jobData?.facility?.name || ''}</span>
        </div>
      </div>
    </div>
  );
}

export default Permanent
