import React from "react";
import classes from "./AgencyBenefits.module.scss";
import { ReactComponent as IconCheckmark } from '../../../../assets/img/icons/checkmark.svg'

const AgencyBenefits = ( { agencyData, ...props } ) => {
  return (
    <div className={`${classes["benefits"]}`}>
      <h5>AVAILABLE AGENCY BENEFITS</h5>
      <div className="row">
        {(agencyData.health_insurance && agencyData.first_day_health_insurance)
          ? <div className='col-6 mb-2'><IconCheckmark width={20} className={'me-3'} fill="#86c632" />Day 1 Health Insurance</div>
          : agencyData.health_insurance ? <div className='col-6 mb-2'><IconCheckmark width={20} className={'me-3'} fill="#86c632" />Health Insurance</div> : null}

        {(agencyData.health_insurance && agencyData.contribute_to_health_insurance)
          ? <div className='col-6 mb-2'><IconCheckmark width={20} className={'me-3'} fill="#86c632" />Contribute to Health Insurance</div>
          : null}

        {(agencyData.dental)
          ? <div className='col-6 mb-2'><IconCheckmark width={20} className={'me-3'} fill="#86c632" />Dental Insurance</div>
          : null}

        {(agencyData.vision)
          ? <div className='col-6 mb-2'><IconCheckmark width={20} className={'me-3'} fill="#86c632" />Vision Insurance</div>
          : null}

        {(agencyData.life)
          ? <div className='col-6 mb-2'><IconCheckmark width={20} className={'me-3'} fill="#86c632" />Life Insurance</div>
          : null}

        {(agencyData.housing && agencyData.furnished_housing)
          ? <div className='col-6 mb-2'><IconCheckmark width={20} className={'me-3'} fill="#86c632" />Housing Furnished</div>
          : agencyData.housing ? <div className='col-6 mb-2'><IconCheckmark width={20} className={'me-3'} fill="#86c632" />Housing</div> : null}

        {(agencyData.housing && agencyData.allow_pets)
          ? <div className='col-6 mb-2'><IconCheckmark width={20} className={'me-3'} fill="#86c632" />Allow Pets</div>
          : null}

        {(agencyData.pay_cycle && agencyData.pay_cycle === 'weekly')
          ? <div className='col-6 mb-2'><IconCheckmark width={20} className={'me-3'} fill="#86c632" />Pay - Weekly</div>
          : null}

        {(agencyData.pay_cycle && agencyData.pay_cycle === 'biweekly')
          ? <div className='col-6 mb-2'><IconCheckmark width={20} className={'me-3'} fill="#86c632" />Pay - Every Two Weeks</div>
          : null}

        {(agencyData.license_reimbursement)
          ? <div className='col-6 mb-2'><IconCheckmark width={20} className={'me-3'} fill="#86c632" />Licensure Reimbursement</div>
          : null}

        {(agencyData.license_reimbursement_help)
          ? <div className='col-6 mb-2'><IconCheckmark width={20} className={'me-3'} fill="#86c632" />Licensure Process Assistance</div>
          : null}

        {(agencyData.pay_compliance_cost)
          ? <div className='col-6 mb-2'><IconCheckmark width={20} className={'me-3'} fill="#86c632" />Paid Compliance Costs</div>
          : null}

        {(agencyData.loyalty_program)
          ? <div className='col-6 mb-2'><IconCheckmark width={20} className={'me-3'} fill="#86c632" />Loyalty Program</div>
          : null}

        {(agencyData.holiday_pay)
          ? <div className='col-6 mb-2'><IconCheckmark width={20} className={'me-3'} fill="#86c632" />Holiday Pay</div>
          : null}

        {(agencyData.pto)
          ? <div className='col-6 mb-2'><IconCheckmark width={20} className={'me-3'} fill="#86c632" />Paid Time Off</div>
          : null}

        {(agencyData.rental_car)
          ? <div className='col-6 mb-2'><IconCheckmark width={20} className={'me-3'} fill="#86c632" />Rental Car</div>
          : null}

        {(agencyData['401k'])
          ? <div className='col-6 mb-2'><IconCheckmark width={20} className={'me-3'} fill="#86c632" />Offer 401k</div>
          : null}

        {(agencyData['401k_contribution'])
          ? <div className='col-6 mb-2'><IconCheckmark width={20} className={'me-3'} fill="#86c632" />Offer Matching 401k</div>
          : null}

        {(agencyData.joint_comm)
          ? <div className='col-6 mb-2'><IconCheckmark width={20} className={'me-3'} fill="#86c632" />Joint Commission Certified</div>
          : null}

        {(agencyData.nationwide)
          ? <div className='col-6 mb-2'><IconCheckmark width={20} className={'me-3'} fill="#86c632" />Nationwide</div>
          : null}

        {(agencyData.direc_of_nurse)
          ? <div className='col-6 mb-2'><IconCheckmark width={20} className={'me-3'} fill="#86c632" />Director of Nursing</div>
          : null}

        {(agencyData['24_7'])
          ? <div className='col-6 mb-2'><IconCheckmark width={20} className={'me-3'} fill="#86c632" />24/7 Customer Support</div>
          : null}

        {(agencyData.ceu_reimbursement)
          ? <div className='col-6 mb-2'><IconCheckmark width={20} className={'me-3'} fill="#86c632" />CEU Reimbursement</div>
          : null}
      </div>
    </div>
  );
};

export default AgencyBenefits;
