import React, {Fragment} from "react";
import { ReactComponent as StarIcon } from "../../assets/img/icons/icon-star.svg";
import {AvatarProfile, TagNurse} from '../../index'
import ReactTooltip from 'react-tooltip'
import PropTypes from "prop-types";

import css from './CandidateCard.module.scss'
import Avatar from "react-avatar";
import Checkbox from "../Checkbox/Checkbox";

const CandidateCard = ({data, onClick, activeId, isEditMode, onSelectCandidate, selectedCandidates, selectAll, ...props}) => {
  const size = 33;
  const sizeRecruiter = 18;
  const { channel_id, profession, avatarData, tags, specialties, hasUnread, lastSent, isFavorite, lastMessage, isAnonymous, recruiterAssigned } = data;
  const handleOnClick = (e) => {
    if (onClick) {
      onClick(data, e);
    }
  }

  return (
    <div className={`${css['candidate-card']} ${+activeId === +data.id ? css['active'] : ''}`.trim()} onClick={handleOnClick}>

      {hasUnread && <span className={`${css['unread']} ${isEditMode ? css['unreadPosition'] : ''}`} />}

      {isEditMode && <Checkbox isChecked={selectedCandidates.includes(data.channel_id)} color='blue'
                               className={`${css['checkbox']}`} onChange={onSelectCandidate} id={data.id} datasetProps={{'data-channel-id': channel_id}}/>}

      <div className={`${css['header']} ${isEditMode ? css['headerPosition'] : ''}`}>
        <span className={css['last-sent']} >{lastSent}</span>
        <div className={css['has-unread']}>
          <div style={{margin: '0 8px 0 10px'}}>
            {isAnonymous ?
              <Avatar
                maxInitials={1}
                name={avatarData.full_name}
                round
                size={size}
                style={{top: '-2px'}}
                color="#a2a5ac"
                title={avatarData.full_name.split(/\s/).reduce((response,word)=> response+=word.slice(0,1),'').toUpperCase()}
              />
              : (<Fragment> {(!avatarData.profile_image) ? (
                <Fragment>
                  {
                    !avatarData.full_name || !avatarData.full_name.trim()
                      ? <Avatar
                        round
                        size={size}
                        src="https://s3.us-west-2.amazonaws.com/qa-docs.wanderly.us/profile-photo.svg"
                        color={'#fa8340'}
                      />
                      : <Avatar
                        maxInitials={2}
                        name={avatarData.full_name}
                        round
                        size={size}
                        title={avatarData.full_name.split(/\s/).reduce((response,word)=> response+=word.slice(0,1),'').toUpperCase()}
                        style={{top: '-2px'}}
                      />
                  }
                </Fragment>
              ) : (
                <Avatar
                  round
                  size={size}
                  title={avatarData.full_name.split(/\s/).reduce((response,word)=> response+=word.slice(0,1),'').toUpperCase()}
                  src={avatarData.profile_image}
                  style={{top: '-2px'}}
                />
              )} </Fragment>)}
          </div>
        </div>

        <div className={css['candidate-data']}>
          {!isAnonymous && <div className={css['name']}>{avatarData.full_name}  {isFavorite && <span className={css['favorite']}><StarIcon fill="#f7c132" /></span>} </div>}

          <div className={css['professional']}>
            <div className={css['profession']}>{profession}</div>
            {specialties.length > 0  && <Fragment>
              <ReactTooltip
                place={'top'}
                className={`rc-tooltip ${css['specialty-tag-tooltip']}`}
                effect="solid"
                multiline={true}
                id={`specialty-tag-${+data.id}`}
                backgroundColor={'#7B7B7C'}
                arrowColor={'#7B7B7C'}
                borderColor={'#7B7B7C'}
              >
                {specialties.join(', ')}
              </ReactTooltip>
            </Fragment>}

            <div className={css['specialties-container']} data-tip data-for={`specialty-tag-${+data.id}`} >
              {specialties.slice(0, 3).map((specialty, i) => {
                return  <span key={'CC_specialties_' + i} className={css['specialty-tag']}><span  className={css['dot-tag']}>·</span> {specialty}</span>
              }) }
            </div>
            {isAnonymous && isFavorite && <span className={css['favorite']}><StarIcon fill="#f7c132" /></span>}
          </div>
        </div>
      </div>

      <div className={css['body']}>
        {lastMessage && <div className={css['last-message']}>{lastMessage}</div>}

        {recruiterAssigned && (<Fragment>
          <ReactTooltip
            place={'top'}
            className={`rc-tooltip ${css['specialty-tag-tooltip']}`}
            effect="solid"
            multiline={true}
            id={`recruiter-avatar-${data.id}`}
            backgroundColor={'#7B7B7C'}
            arrowColor={'#7B7B7C'}
            borderColor={'#7B7B7C'}
          >
            {`${recruiterAssigned.first_name} ${recruiterAssigned.last_name}`}
          </ReactTooltip>
          <div data-tip data-for={`recruiter-avatar-${data.id}`}>
            {(!recruiterAssigned.image) ? (
              <Fragment>
                {
                  !recruiterAssigned.first_name || !recruiterAssigned.first_name.trim()
                    ? <Avatar
                      round
                      size={sizeRecruiter}
                      src="https://s3.us-west-2.amazonaws.com/qa-docs.wanderly.us/profile-photo.svg"
                      color={'#fa8340'}
                    />
                    : <Avatar
                      maxInitials={2}
                      name={`${recruiterAssigned.first_name} ${recruiterAssigned.last_name}`}
                      round
                      size={sizeRecruiter}
                      style={{position: 'relative', top: '-7px', marginRight: '4px'}}
                    />
                }
              </Fragment>
            ) : (
              <Avatar
                round
                size={sizeRecruiter}
                src={recruiterAssigned.image}
                style={{position: 'relative', top: '-7px', marginRight: '4px'}}
              />
            )}
          </div>
        </Fragment>)}

        {tags.map((t, index) => <TagNurse key={index} type={t.type} label={t.label} />)}
      </div>
    </div>
  );
};

CandidateCard.propTypes = {
  data: PropTypes.object.isRequired,
  activeId: PropTypes.number,
  onClick: PropTypes.func,
  isEditMode: PropTypes.bool,
  onSelectCandidate: PropTypes.func,
  selectedCandidates: PropTypes.array,
  selectAll: PropTypes.bool,
};

CandidateCard.defaultProps = {
  data: {},
  onClick: () => {},
  activeId: undefined,
  isEditMode: false,
  onSelectCandidate: () => {},
  selectedCandidates: [],
  selectAll: false
};

export default CandidateCard;
