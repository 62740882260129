import React, {Fragment, useState} from 'react';

import { ReactComponent as GreenCheckmarkIcon } from '../../assets/img/icons/green-checkmark.svg';
import {
  isOwnMessage,
} from './helpers';
import css from './Chat.module.scss';

import ChatAvatar from './ChatAvatar';
import PropTypes from "prop-types";
import ChatTime from "./ChatTime";

function ChatMiscDocuments( { message, identity = 0 , details, isAnonymous = false, isRecruiterSite = false, onClick = () => {} }) {
  const [showOtherDocuments, setShowOtherDocuments] = useState(false);
  const { misc_documents } = message.attributes;
  const sentDocuments = JSON.parse(misc_documents);
  const documents = sentDocuments.shared_profile;

  return (
    <div
      className={`${css['message-single']} ${
        isOwnMessage(message, identity, isRecruiterSite) ? css.receiver : css.sender
      }`}
    >
      <div className={css['message-bubble-container']}>
        <div className={`col-auto ${isOwnMessage(message, identity, isRecruiterSite) ? 'ms-2' : 'me-2'}`}>
          <ChatAvatar message={message} identity={identity} details={details} isAnonymous={isAnonymous} isRecruiterSite={isRecruiterSite} />
        </div>
        <div className="col">
          <div className={`${css['misc-documents-card']} ${
            isRecruiterSite ? css['misc-documents-card-sender'] : css['misc-documents-card-receiver']
          }`} onClick={() => {onClick(sentDocuments.id, 'profile')}}>
            <div className={css['misc-documents-card--title']}>Misc. Documents</div>
            <div className={css['misc-documents-card--documents']}>

              {documents.hasSummary && <div className={css['document-title']}>
                Summary <GreenCheckmarkIcon className={css['icon-checkmark']} />
              </div>}

              {documents.hasAboutMe && <div className={css['document-title']}>
                About Me <GreenCheckmarkIcon className={css['icon-checkmark']} />
              </div>}

              {documents.hasProfessional && <div className={css['document-title']}>
                Professional Info <GreenCheckmarkIcon className={css['icon-checkmark']} />
              </div>}

              {/* Resume */}
              {documents.resumes &&
              documents.resumes.length > 0
                ? documents.resumes.map((d, i) => (
                  <div key={`resume_${i}`} className={css['document-title']}>
                    {d.name}
                    <GreenCheckmarkIcon className={css['icon-checkmark']} />
                  </div>
                ))
                : null}


              { (documents.identifications ||
                documents.certifications ||
                documents.licenses ||
                documents.medicals ||
                documents.others ||
                documents.hasChecklist) && !showOtherDocuments && (
                <div
                  className={css['document-title']}
                  onClick={() => {
                    setShowOtherDocuments(!showOtherDocuments);
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  ...
                </div>
              )}

              {showOtherDocuments && (
                <Fragment>
                  {/* Identification */}
                  {documents.identifications &&
                  documents.identifications.length > 0
                    ? documents.identifications.map((d, i) => (
                      <div key={`identification_${i}`} className={css['document-title']}>
                        {d.name}
                        <GreenCheckmarkIcon className={css['icon-checkmark']} />
                      </div>
                    ))
                    : null}

                  {/* Certification */}
                  {documents.certifications &&
                  documents.certifications.length > 0
                    ? documents.certifications.map((d, i) => (
                      <div key={`certification_${i}`} className={css['document-title']}>
                        {d.name}
                        <GreenCheckmarkIcon className={css['icon-checkmark']} />
                      </div>
                    ))
                    : null}

                  {/* License */}
                  {documents.licenses &&
                  documents.licenses.length > 0
                    ? documents.licenses.map((d, i) => (
                      <div key={`license_${i}`} className={css['document-title']}>
                        {d.name}
                        <GreenCheckmarkIcon className={css['icon-checkmark']} />
                      </div>
                    ))
                    : null}

                  {/* Medicals */}
                  {documents.medicals &&
                  documents.medicals.length > 0
                    ? documents.medicals.map((d, i) => (
                      <div key={`medical_${i}`} className={css['document-title']}>
                        {d.name}
                        <GreenCheckmarkIcon className={css['icon-checkmark']} />
                      </div>
                    ))
                    : null}

                  {/* Others */}
                  {documents.others &&
                  documents.others.length > 0
                    ? documents.others.map((d, i) => (
                      <div key={`other_${i}`} className={css['document-title']}>
                        {d.name}
                        <GreenCheckmarkIcon className={css['icon-checkmark']} />
                      </div>
                    ))
                    : null}

                  {/* Checklists */}
                  {documents.hasChecklist && documents.checklists
                    ? Object.keys(documents.checklists).map((k, i) => (
                      <div key={`checklist_${i}`} className={css['document-title']}>
                        {k}
                        <GreenCheckmarkIcon className={css['icon-checkmark']} />
                      </div>
                    ))
                    : null}
                </Fragment>
              )}
            </div>
          </div>
          <ChatTime isOwnMessage={isOwnMessage(message, identity, isRecruiterSite)} date={message.dateUpdated} />
        </div>
      </div>
    </div>
  );
}

ChatMiscDocuments.propTypes = {
  message: PropTypes.object.isRequired,
  identity: PropTypes.number.isRequired,
  details: PropTypes.object.isRequired,
  isAnonymous: PropTypes.bool,
};

ChatMiscDocuments.defaultProps = {
  message: {},
  identity: 0,
  details: {},
  isAnonymous: false
};

export default ChatMiscDocuments;
