export const DropdownSelectAsyncCustomStyles = {
  valueContainer: (provided, state) => ({
    ...provided,
    textOverflow: "ellipsis",
    maxWidth: "90%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    fontSize: '13px',
    marginTop: '-2px',
    color: '#303030',
    display: 'flex',
    padding: '2px 2px',
    boxShadow: 'none',
    border: 'none'
  }),
  indicatorsContainer: (provided, { data, isFocused }) => ({
    ...provided,
    height: 30,
  }),
  placeholder: (provided, { data, isFocused }) => ({
    ...provided,
    position: 'relative',
    transform: null,
    color: '#A2A5AC',
    fontSize: '13px',
  }),
  menu: (css) => ({
    ...css,
    borderRadius: '6px',
    backgroundColor: '#FFFFFF'
  }),
  option: (provided, { data, isDisabled, isFocused, isSelected }) => {
    return ({
      ...provided,
      color: '#303030',
      cursor: 'pointer',
      padding: '5px 10px',
      backgroundColor: isDisabled
        ? null
        : isSelected
          ? (isFocused) ? '#EDEDED' : 'transparent'
          : isFocused
            ? '#EDEDED'
            : null
    })
  },
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1
    const transition = 'opacity 300ms'
    return { ...provided, opacity, transition }
  },
  multiValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1
    const transition = 'opacity 300ms'
    return { ...provided,
      width: 'fit-content',
      background: '#DFDFDF',
      borderRadius: '11px',
      color: '#303030',
      opacity,
      transition,
      ':hover': {
        borderColor: '@DFDFDF'
      }
    }
  },
  control: (provided) => ({
    ...provided,
    borderRadius: '0'
  }),
  multiValueLabel: (provided, state) => {
    return { ...provided,
      padding: '3px 7px'
    }
  },
}
