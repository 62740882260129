import React, { useEffect, useRef } from 'react';
import { components } from 'react-select';

import { ReactComponent as IconClose } from '../../assets/img/icons/closeDropdown.svg';
import { ReactComponent as IconProfession } from '../../assets/img/icons/icon-profession.svg';
import DropdownControl from '../DropdownControl';

import './ProfessionDropdown.scss';

const ProfessionDropdown = ({
  options,
  professionId,
  onSelectOption,
  onClear,
  value,
  isControlled = false,
  showIcon,
  showPlaceholder = true,
  showIndicator = true,
  name = 'profession',
  className= '',
  placeholder = 'Select Profession',
  ...props
}) => {
  const dropdownRef = useRef(null);

  const ClearIndicator = ({ children, ...props }) =>
    showIndicator && !isControlled ? (
      <components.Control {...props} className="">
        <IconClose
          className="icon-close"
          onClick={onClear}
          title="Click to remove profession"
        />
      </components.Control>
    ) : null;

  const getValue = () => {
    if (isControlled) {
      return value ? options.filter((option) => option.value === value) : null;
    }

    return !isNaN(professionId)
      ? options.filter((option) => option.value === professionId)
      : professionId;
  };

  return (
    <DropdownControl
      ref={dropdownRef}
      classname={`profession ${className}`}
      customComponents={{
        ClearIndicator,
      }}
      icon={showIcon ? <IconProfession /> : false}
      isClearable
      name={name}
      onChange={onSelectOption}
      options={options}
      placeholder={showPlaceholder ? placeholder : '' }
      value={getValue()}
      {...props}
    />
  );
};

export default ProfessionDropdown;
