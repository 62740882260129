import React from 'react';
import css from './DocumentIcon.module.scss';
import { ReactComponent as pictureIcon } from "../../assets/img/icons/pictureIcon.svg";
import { ReactComponent as documentIcon } from "../../assets/img/icons/documentIcon.svg";

const DocumentIcon = ({ name, className }) => {
  const imageExtensions = ['svg', 'png', 'jpg', 'jpeg', 'bmp'];
  const fileExt = name ? '' + name.split('.').pop() : null;
  const isImage = fileExt && imageExtensions.indexOf(fileExt) > -1;
  const Picture = isImage ? pictureIcon : documentIcon;

  return <span className={css['document-icon'] + ' ' + (isImage ? css['picture-icon'] : '') + ' ' + className}>
    <Picture />
  </span>;
};


export default DocumentIcon;
