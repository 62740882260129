import React from 'react';

import { ReactComponent as GreenCheckmarkIcon } from '../../assets/img/icons/green-checkmark.svg';
import {getLicenseName, isOwnMessage} from './helpers';
import JobInfo from './JobInfo';

import css from './Chat.module.scss';

const QuickApplicationMessage = ({ job, isTooltip = false, ownMessage, isRecruiterSite = false, triggerApplyNowModal= null, onClick  = () => {}, attributes, nurse = {} }) => {
  if (!job) {
    return false
  }

  const { quick_applied } = nurse;

  const specialties = job?.specialities || job?.specialties || [];
  const application = quick_applied ? quick_applied.find(a => a.id === attributes.application_id) : null;

  return (
    <div className={`${css['quick-app-card']} ${isTooltip && css['is-tooltip']} ${isRecruiterSite ? '' : css['own-message']}`} onClick={() => {onClick(attributes.application_id, 'quick_application')}}>
      <div className={css['quick-app-card--title']}>Quick Application</div>
      <div className={css['quick-app-card--documents']}>
        <JobInfo job={job} className={css['quick-app-card--job']} isRecruiterSite={isRecruiterSite} />
        <div className={css['document-title']}>
          <div className={css['document-title-content']}> Personal info </div>
          <GreenCheckmarkIcon className={css['icon-checkmark']} />
        </div>
        <div className={css['document-title']}>
          <div className={css['document-title-content']}>
            Specialties - {specialties.join(', ')}
          </div>
          <GreenCheckmarkIcon className={css['icon-checkmark']} />
        </div>

        {job.required_licenses && job.required_licenses.length > 0 && (
          <div className={css['document-title']}>
            <div className={css['document-title-content']}>
              License - {getLicenseName(job.required_licenses)} License
            </div>
            <GreenCheckmarkIcon className={css['icon-checkmark']} />
          </div>
        )}

        {application && application.has_resume && (<div className={css['document-title']}>
          <div className={css['document-title-content']}> Resume</div>
          <GreenCheckmarkIcon className={css['icon-checkmark']}/>
        </div>)}

        {/* TODO - Uncomment this line when Product Team decide to make Resume mandatory <div className={css['document-title']}>
          <div className={css['document-title-content']}> Employment History</div>
          <GreenCheckmarkIcon className={css['icon-checkmark']}/>
        </div>*/}
      </div>
    </div>
  );
};

export default QuickApplicationMessage;
