import React from "react";
import classes from "./Button.module.scss";
import PropTypes from "prop-types";

const Button = ({ type = 'primary', ...props } ) => {
  const { className = "", children } = props;

  return (
    <button {...props} className={`${classes['btn']} ${classes[type]} ${className}`.trim()}>
      {children}
    </button>
  );
};

Button.propTypes = {
  /**
   * Is this the principal call to action on the page?
   */
  type: PropTypes.oneOf(['primary', 'secondary', 'rounded-gray', 'gray', 'white', 'special', 'button', 'submit']),
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
  /**
   * What background color to use
   */
  className: PropTypes.string,
  /**
   * How large should the button be?
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
};

Button.defaultProps = {
  type: 'primary',
  onClick: undefined,
  className: ''
};

export default Button;
