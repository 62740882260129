import React from 'react'
import { components } from 'react-select'
import AsyncSelect from 'react-select/async';
import { DropdownSelectAsyncCustomStyles } from './DropdownSelectAsyncCustomStyles'
import _uniqueId from 'lodash/uniqueId'
import { ReactComponent as IconClose } from '../../assets/img/icons/closeDropdown.svg';

import classes from './DropdownSelectAsync.module.scss'

const Control = ({ children, ...props }) => {
  const { icon } = props.selectProps;
  const values = props.getValue();
  const css = values.length > 1 ? classes['multi'] : values.length === 1 ? classes['single'] : '';
  const newIcon = icon ? {...icon, props: { style: { marginLeft: '5px', width: '15px', height: '15px' }}} : null;

  return (
    <components.Control {...props} className={`${classes['dropdown-control']} ${css}`.trim()}>
      {props.selectProps.iconAlign === 'left' && icon && <span style={{display: 'flex', marginTop: '-2px', marginLeft: '5px'}}>{newIcon}</span>}
      {children}
      {props.selectProps.iconAlign === 'right' && icon && <span style={{display: 'flex', marginTop: '-2px', marginRight: '10px'}}>{newIcon}</span>}
    </components.Control>
  )
}

const Placeholder = ({ children }) => {
  return <div className={classes['select__placeholder']}>{children}</div>;
}

const Checkbox = ({onChange, isChecked, className, disabled = false, color = ''}) => {
  const id = _uniqueId('checkbox-');
  const css = color === 'yellow' ? classes['yellow-checkbox'] : color === 'green' ? classes['green-checkbox'] : classes.checkbox;
  return (
    <label htmlFor={id} className={`${css} ${className}`.trim()} >
      <input type='checkbox'
             id={id}
             disabled={disabled}
             checked={isChecked}
             onChange={onChange} />
    </label>
  );
}

const Option = (props) => {
  const {isMulti, selectProps, data} = props;

  return (
    <components.Option {...props} className={classes['option']}>
      {isMulti && <Checkbox onChange={() => null} isChecked={props.isSelected} color={selectProps.optionColor} className={classes['checkbox']}  />}
      <label>{props.label}</label> {(selectProps.showCounters && (data.count || +data.count === 0)) ? <span className={classes['jobs-count']}>{data.count}</span> : null}
    </components.Option>)
}

const SingleValue = ({ children, getValue, hasValue, isMulti, ...props }) => {
  if (hasValue && !isMulti) {
    return (
      getValue()[0].label
    )
  }

  return (
    <components.SingleValue {...props}>
      {children}
    </components.SingleValue>
  )
};

const DropdownIndicator =() => null;

const IndicatorSeparator = () => null;

const DropdownSelectAsync = ({ classname, name, isClearable = false, clearAction, showPlaceholder = true, placeholder= 'Select Profession', iconAlign = "left", showCounters = true, ...props }) => {
  const ClearIndicator = ({ children, ...props }) =>
    isClearable ? (
      <components.Control {...props} className={classes['clear-indicator']}>
        <IconClose
          className="icon-close"
          onClick={clearAction}
          title="Click to remove profession"
        />
      </components.Control>
    ) : null;

  return (
    <AsyncSelect
      className={classes['dropdown-control']}
      name={name}
      classNamePrefix={classname}
      styles={DropdownSelectAsyncCustomStyles}
      isClearable={isClearable}
      placeholder={ showPlaceholder ? placeholder : '' }
      iconAlign={iconAlign}
      showCounters={showCounters}
      components={{
        Control,
        Option,
        SingleValue,
        IndicatorSeparator,
        Placeholder,
        DropdownIndicator,
        ClearIndicator
      }}
      {...props}
    />
  )
};

export default DropdownSelectAsync
