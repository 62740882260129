import React from "react";
import css from "./FilterDropdown.module.scss";
import { ReactComponent as FilterIcon } from "../../assets/img/icons/icon-filter.svg";
import Popup from "reactjs-popup";

const FilterDropdown = ( { children, onClose, classname = '' } ) => {
  return (
    <Popup
      onClose={onClose}
      position="bottom left"
      trigger={
      <button className={css["filter-dropdown-button"]}>
        <FilterIcon className={css["filter-icon"]} />
      </button>
    }>
      <div className={`${css["filter-dropdown-wrapper"]}  ${classname}`}>
        {children}
      </div>
    </Popup>
  );
};

export default FilterDropdown;
