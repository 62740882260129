import React, { Fragment, useEffect, useRef, useState } from 'react';
import css from "../Chat/Chat.module.scss";
import {getPaymentTypeAbbr} from "../Chat/helpers";
import {formatMoney} from "../../utils/utils";
import ReactTooltip from "react-tooltip";

const Rate = ({job}) => {
  const minPayment = +job.payment_min;
  const maxPayment = +job.payment_max;
  const jobType = +job.job_type;
  const isHourly = job.payment_type == 'hourly';

  if (job.hide_pay_rate) {
    return <div className={css['job-card--contact']}>Contact recruiter for more details</div>;
  }

  const isPermanentRateDifferent = maxPayment.toFixed() !== minPayment.toFixed() && maxPayment.toFixed() > 0;
  const permanentRate = maxPayment.toFixed(2) > minPayment.toFixed(2) ? minPayment.toFixed(2) : minPayment.toFixed(2);

  return (
    <div className={css['job-card--rate']}>
      {[2, 3, 4, 5].includes(+jobType) ? (
        <Fragment>
          <ReactTooltip
            arrowColor="#7B7B7C"
            backgroundColor="#7B7B7C"
            borderColor="#7B7B7C"
            className={`rc-tooltip ${css['rc-tooltip']} ${css['proposal-amount-tooltip']}`}
            effect="solid"
            id={`proposal-rate-${job.id}`}
          >
            <span className={css['job-card-price']}>
              {isHourly ? formatMoney(minPayment.toFixed(2), 2) : formatMoney(minPayment.toFixed(), 0)}
            </span>
            {(maxPayment > 0 && maxPayment > minPayment) && (
              <>
                <span className="separator"> - </span>
                <span className={css['job-card-price']}>
                  {isHourly ? formatMoney(maxPayment.toFixed(2), 2) : formatMoney(maxPayment.toFixed(), 0)}
                </span>
              </>
            )}
            /{getPaymentTypeAbbr(job.payment_type)}
          </ReactTooltip>

          <div data-for={`proposal-rate-${job.id}`} data-tip className="flex">
            <div className={css['job-card-price-sign']}>$</div>
            <span className={css['job-card-price']}>
              {isHourly ? formatMoney(minPayment.toFixed(2), 2, '') : formatMoney(minPayment.toFixed(), 0, '')}
            </span>
          </div>
        </Fragment>
      ) : (
        <Fragment>

          <ReactTooltip
            arrowColor="#7B7B7C"
            backgroundColor="#7B7B7C"
            borderColor="#7B7B7C"
            className={`rc-tooltip ${css['rc-tooltip']} ${css['proposal-amount-tooltip']}`}
            effect="solid"
            id={`proposal-rate-${job.id}`}
          >
            <span className={css['job-card-price']}>
              {formatMoney(permanentRate, 0)}/{getPaymentTypeAbbr(job.payment_type)}
            </span>
          </ReactTooltip>

          <div data-for={`proposal-rate-${job.id}`} data-tip className="flex">
            <div className={css['job-card-price-sign']}>$</div>
            <span className={css['job-card-price']}>
              {formatMoney(permanentRate, 0, '')}
            </span>
          </div>

        </Fragment>
      )}
      <small>/{getPaymentTypeAbbr(job.payment_type)}{isPermanentRateDifferent ? '+' : ''}</small>
    </div>
  );
}

export default Rate;
