import React, { useEffect, useRef, useState } from 'react'
import { usePopper } from 'react-popper';
import classes from './AccountToggle.module.scss';
import { ReactComponent as Caret } from "../../assets/img/caret.svg";

const AccountToggle = (props) => {
  const [isOpen, setIsOpen] = useState(false)
  const referenceRef = useRef(null);
  const popperRef = useRef(null);
  const { styles, attributes } = usePopper(
    referenceRef.current,
    popperRef.current,
    {
      placement: "bottom",
      modifiers: [
        {
          name: "offset",
          enabled: true,
          options: {
            offset: [0, 10]
          }
        }
      ]
    }
  );

  function handleDocumentClick(event) {
    if (!referenceRef?.current?.contains(event.target)) {
      setIsOpen(false);
    }
  }

  useEffect(() => {
    // listen for clicks and close dropdown on body
    document.addEventListener("mousedown", handleDocumentClick);
    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
    };
  }, []);

  // Props
  const placeholder = props.placeholder || <span className={`sr-only ${classes['sr-only']}`.trim()}>{props.text || ''}</span>
  const list = props.children || null;
  const color = props.caretColor || '#303030';

  return (
    <React.Fragment>
      <div className={`${classes['account-toggle-container']} account-toggle`} ref={referenceRef}>
        <button className={`${classes['account-toggle']} ${props.buttonClassName || ''}`.trim()}
                type='button'
                onClick={() => setIsOpen(!isOpen)}>
          {placeholder}
          {props.account && <span className={classes['username']}>{props.account.full_name}</span>}
          <Caret className="hover-img" width={11} height={17} fill={color} style={{ position: 'relative', left: '12px', top: '2px'}}/>
        </button>

        {isOpen ? <ul className={`${classes['account-toggle-list']} account-toggle-options ${props.listClassName || ''}`.trim()}
                      role={'menu'}
                      ref={popperRef}
                      style={styles.popper}
                      {...attributes.popper}>
                    {list}
                  </ul> : null}
      </div>
    </React.Fragment>
  )
}

export default AccountToggle
