import React, {Fragment} from "react";
import TagNurse from '../TagNurse/TagNurse'
import Avatar from 'react-avatar'
import ReactTooltip from 'react-tooltip'
import PropTypes from "prop-types";
import Checkbox from "../Checkbox/Checkbox";

import classes from './ChatCard.module.scss'

const ChatCard = ({ data, onClick, activeId, isEditMode, onSelectAgency, selectedAgencies, selectAll, avatarComponent = null }) => {
  const { id, channelId, agency, avatarData, tags,  hasUnread, lastSent, rating, lastMessage, isAnonymous, review } = data;
  const size = 33;

  const handleOnClick = (e) => {
    if (onClick) {
      onClick(data, e);
    }
  }

  return (
    <div className={`${classes['agency-card']} ${+activeId === +data.id ? classes['active'] : ''}`.trim()} onClick={handleOnClick}>

      {hasUnread && <div className={classes['unread']} />}

      {isEditMode && <Checkbox isChecked={selectedAgencies.includes(data.channelId)} color='blue'
                               className={classes['checkbox']} onChange={onSelectAgency} id={data.id} datasetProps={{'data-channel-id': channelId}}/>}

      <div className={classes['last-sent']} >{lastSent}</div>

      <div className={`${classes['logo-container']}`}>
        <div className={classes['has-unread']}>
          <div style={avatarComponent ? null : {margin: '0 8px 0 10px'}}>
            {avatarComponent ? avatarComponent : (!avatarData.profile_image) ? (
              <Fragment>
                {
                  !avatarData.full_name || !avatarData.full_name.trim()
                    ? <Avatar
                      round
                      size={size}
                      src="https://s3.us-west-2.amazonaws.com/qa-docs.wanderly.us/profile-photo.svg"
                      color={'#fa8340'}
                    />
                    : <Avatar
                      maxInitials={2}
                      name={avatarData.full_name}
                      round
                      size={size}
                      style={{top: '-2px'}}
                    />
                }
              </Fragment>
            ) : (
              <Avatar
                round
                size={size}
                src={avatarData.profile_image}
                style={{top: '-2px'}}
              />
            )}
          </div>
        </div>

      </div>

      <div className={classes['body-container']}>
        <div className={classes['agency-data']}>
          <div className={classes['agency-data-name']}>{agency}</div>
          <div className={classes['agency-data-recruiter']}>
            <div className={classes['recruiter']}>{avatarData.full_name}</div>
          </div>
        </div>
        <ReactTooltip
          arrowColor="#A2A5AC"
          backgroundColor="#A2A5AC"
          borderColor="#A2A5AC"
          className={`rc-tooltip ${classes['tooltip']}`}
          effect="solid"
          id={`last-message-${id}`}
          multiline
          place="top"
        >
          {lastMessage}
        </ReactTooltip>
        {lastMessage && <div className={classes['last-message']} data-tip data-for={`last-message-${id}`} >{lastMessage}</div>}
        <div>
          {tags.map((t, index) => <TagNurse key={index} type={t.type} label={t.label} />)}
        </div>
      </div>

    </div>
  );
};

ChatCard.propTypes = {
  data: PropTypes.object.isRequired,
  activeId: PropTypes.number,
  onClick: PropTypes.func,
  isEditMode: PropTypes.bool,
  onSelectAgency: PropTypes.func,
  selectedAgencies: PropTypes.array,
  selectAll: PropTypes.bool,
};

ChatCard.defaultProps = {
  data: {},
  onClick: () => {},
  activeId: undefined,
  isEditMode: false,
  onSelectAgency: () => {},
  selectedAgencies: [],
  selectAll: false
};


export default ChatCard;
