import React, {Fragment} from 'react';
import Avatar from 'react-avatar';

import { ReactComponent as VirtualRecruiterIcon } from '../../assets/img/icons/chat/virtual-assistant.svg';
import css from './Chat.module.scss';
import PropTypes from "prop-types";

function ChatAvatar({ message, identity, details, isAnonymous, isRecruiterSite = false, style = {} }) {
  const isOwnMessage = +message.author === +identity;
  const { viaAutoReply, viaVR, recruiter_id } = message.attributes;
  const { nurse_profile, recruiter } = details;
  let profileImage, profileName;

  if (isRecruiterSite) {
    profileImage = isOwnMessage ? recruiter?.image : nurse_profile?.image;
    profileName = isOwnMessage
      ? `${recruiter?.first_name || recruiter?.firstname}  ${recruiter?.last_name || recruiter?.lastname}`
      : `${nurse_profile?.full_name?.trim() ? nurse_profile?.full_name : `${nurse_profile?.first_name} ${nurse_profile?.last_name}`}`;
  } else {
    profileImage = isOwnMessage ? nurse_profile?.image : recruiter?.image;
    profileName = isOwnMessage
      ? `${nurse_profile?.full_name?.trim() ? nurse_profile?.full_name : `${nurse_profile?.first_name} ${nurse_profile?.last_name}`}`
      : `${recruiter?.first_name || recruiter?.firstname} ${recruiter?.last_name || recruiter?.lastname}`;
  }

  return (
    <Fragment>
      {viaAutoReply || viaVR ? (
        <VirtualRecruiterIcon className={css['vr-image']} style={style} />
      ) : isAnonymous && !isOwnMessage && +message.author !== recruiter_id ?
        <Avatar className={css['profile-image']} name={profileName} round size="25" style={style} maxInitials={1} color="#a2a5ac" />
      : profileImage ? (
        <img className={css['profile-image']} src={profileImage} alt={profileName} style={style}/>
      ) : (
        <Avatar className={css['profile-image']} name={profileName} round size="25" style={style} />
      )}
    </Fragment>
  );
}

ChatAvatar.propTypes = {
  message: PropTypes.object.isRequired,
  identity: PropTypes.number.isRequired,
  details: PropTypes.object.isRequired,
  isAnonymous: PropTypes.bool,
};

ChatAvatar.defaultProps = {
  message: {},
  identity: 0,
  details: {},
  isAnonymous: false
};

export default ChatAvatar;
