import React, {Fragment, useCallback} from 'react';
import css from "./RowTable.module.scss";

const RowTable = ({
  columns,
  row,
  className,
  index,
}) => {

  const getColumns = useCallback(() => {
    return columns.map((column, i) => {
      const field = column.key;

      return (<div key={`${row.id}-${i}`} className={css['field-data']} style={column.style ? column.style : {}}>
        {column.render ?  column.render(row, index) : row[field]}
      </div>)
    })
  }, [row]) ;

  return  (
    <div className={`${className}`}>{getColumns()}</div>);
};

export default React.memo(RowTable);
