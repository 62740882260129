import Button from '../Button/Button'
import React from 'react'

import { ReactComponent as FlightIcon } from '../../assets/img/icons/icon-flight.svg'

import css from './Chat.module.scss'
import JobInfo from './JobInfo'
import PropTypes from 'prop-types'
import ChatTime from './ChatTime'

/* prettier-ignore */
function ChatAnonymousCard({ agency, identity, details, disabledQuickApply, disabledApply, onClickQuickApply, onClickApply }) {
  const { nurse, job } = details;
  const now = Date.now();

  return (
    <div className={`${css['message-single']} ${css.sender}`}>
      <div className={css['message-bubble-container']}>
        <div className="col-12">
          <div className={css['anonymous-card']}>
            <div className={css['anonymous-card--info']}>
              <JobInfo job={job} />
            </div>
            <div className={css['anonymous-card--buttons']}>
              {agency && agency.is_qa_enabled ? <Button disabled={disabledQuickApply} onClick={onClickQuickApply} className={css['btn-apply-card']} type="gray">
                {' '}
                <FlightIcon className={css['btn-icon-card']} /> Quick Apply{' '}
              </Button> : null}
              {agency && agency.is_apply_enabled ? <Button disabled={disabledApply} onClick={onClickApply} className={css['btn-apply-card']}>
                {' '}
                <FlightIcon className={css['btn-icon-card']} fill="#fff" /> Quick Apply{' '}
              </Button> : null}
            </div>
          </div>
          <ChatTime isOwnMessage={false} date={now} />
        </div>
      </div>
    </div>
  );
}

ChatAnonymousCard.propTypes = {
  identity: PropTypes.number.isRequired,
  details: PropTypes.object.isRequired
}

ChatAnonymousCard.defaultProps = {
  identity: 0,
  details: {}
}

export default ChatAnonymousCard
