import React, {Fragment} from 'react';
import format from 'date-fns/format';
import isToday from 'date-fns/isToday';
import isYesterday from 'date-fns/isYesterday';

import { ReactComponent as PermanentIcon } from '../../assets/img/icons/icon-permanent.svg';
import { ReactComponent as PlaneIcon } from '../../assets/img/icons/icon-travel.svg';
import { ReactComponent as IRPIcon } from '../../assets/img/icons/icon-irp.svg';
import { ReactComponent as LocalContractIcon } from '../../assets/img/icons/icon-local.svg';
import { ReactComponent as IconInternational } from "../../assets/img/icons/icon-international.svg";
import { ReactComponent as PerDiemIcon } from '../../assets/img/icons/icon-perdiem.svg';
import { ReactComponent as LocumTenensIcon } from '../../assets/img/icons/icon-locumTenens.svg';
export const isRequestInfoButtonDisabled = (details) => {
  if (!details) {
    return false;
  }

  const { requested, recruiterMessageCount, nurse } = details;

  // Recruiter requested Contact Info, but Candidate haven't answer yet
  if (requested && recruiterMessageCount) {
    return true;
  }

  if (!requested && recruiterMessageCount > 0) {
    return false;
  }

  return nurse ? !!nurse.contactShared : false;
};

export const recruiterHasRepliedMessages = (details) => {
  if (!details) {
    return false;
  }

  const { requested, recruiterMessageCount } = details;

  if (recruiterMessageCount && +recruiterMessageCount > 0) {
    return true;
  }

  if (!requested && +recruiterMessageCount === 0) {
    return false;
  }

  return false;
};

export const checkIsAnonymous = (details) => {
  if (!details) {
    return false;
  }

  const { requested, nurse } = details;

  return !(requested || (nurse && nurse.has_applied) || (nurse && nurse.has_quick_applied));
};

export const isFullAppliedDone = (details) => {
  if (!details) {
    return false;
  }

  const { nurse } = details;

  return nurse.fullApplication;
};

export const isQuickAppliedDone = (details) => {
  if (!details) {
    return false;
  }

  const { nurse } = details;

  return nurse.quick_applied;
};

export const getMessageApplied = (isQuick = false, job) => {
  if (!job) {
    return null;
  }

  return `You have submitted a ${isQuick ? 'quick application' : 'application'} for The ${
    job.job_type_label
  } ${job.specialties.join(', ')} role in ${job.location} paying up to $${job.total_compensation}`;
};

export const formatDateForTooltips = (date = '', displayFormat = 'dd.MM.yyyy') => {
  const d = new Date(date);

  if (isToday(d)) {
    return `Today at ${format(d, 'hh:mm a')}`;
  }

  if (isYesterday(d)) {
    return `Yesterday at ${format(d, 'hh:mm a')}`;
  }

  return format(d, displayFormat);
};

export const isOwnMessage = (message, identity, isRecruiterSite = false ) => {
  if (isRecruiterSite) {
    const { recruiter_id } = message.attributes;
    return +message.author === +identity || +message.author === +recruiter_id;
  } else {
    const { nurse_id } = message.attributes;
    return +message.author === +identity || +message.author === +nurse_id;
  }
};

export const extractEmails = (text) => {
  const regex = /(?:^|\s|$)([a-zA-Z0-9.\+_-]+@[a-zA-Z0-9._-]+)/gi;
  const matches = [];
  let match;

  while ((match = regex.exec(text))) {
    matches.push(match[1]);
  }

  return matches;
};

export const extractPhoneNumber = (text) => {
  const regex = /\+?[0-9]?[- \.]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}/gi;
  const matches = [];
  let match;

  while ((match = regex.exec(text))) {
    matches.push(match[0]);
  }

  return matches;
};

export const getLicenseName = (licenses) => {
  if (!licenses || licenses.length == 0) {
    return '';
  }

  return licenses[0].name.replace(' License', '');
};

export const getCertificationName = (certifications) => {
  if (!certifications || certifications.length == 0) {
    return '';
  }

  return certifications.map((c) => c.name).join(', ');
};

export const getTypeJobIcon = (typeJob, jobId, fill = "current", classname = '', width= 0,) => {
  const config = {
    1: <PlaneIcon className={ `${classname} icon-black`} width={width ?? ''} data-for={`icon-${jobId}`} data-tip fill={fill} />,
    2: <PermanentIcon className={ `${classname} icon-black`} width={width ?? ''} data-for={`icon-${jobId}`} data-tip fill={fill} />,
    3: <LocalContractIcon className={ `${classname} icon-black`} width={width ?? ''} data-for={`icon-${jobId}`} data-tip fill={fill} />,
    4: <IRPIcon className={ `${classname} icon-black`} width={width ?? ''} data-for={`icon-${jobId}`} data-tip fill={fill} />,
    5: <PerDiemIcon className={ `${classname} icon-black`} width={width ?? ''} data-for={`icon-${jobId}`} data-tip fill={fill} />,
    6: <LocumTenensIcon className={ `${classname} icon-black`} width={width ?? ''} data-for={`icon-${jobId}`} data-tip fill={fill} />,
  };

  return config[typeJob] || config[1];
};

export const getTypeJobText = (typeJob) => {
  const config = {
    1: 'Travel',
    2: 'Permanent',
    3: 'Local',
    4: 'Internal Resource Pool',
    5: 'Per Diem',
    6: 'Locum Tenens',
  };

  return config[typeJob] || false;
};

export const getProfession = (typeJob) => {
  const config = {
    1: 'RN',
    2: 'Allied',
    3: 'LVN/LPN',
    5: 'Public Health',
    6: 'CNA',
  };

  return config[typeJob] || config[1];
};

export const getPaymentTypeAbbr = (paymentType) => {
  const config = {
    weekly: 'wk',
    yearly: 'yr',
    hourly: 'hr',
    shift: 'shift',
  };

  return config[paymentType] || config['weekly'];
};
