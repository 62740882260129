import React, {Fragment} from "react";
import classes from './NurseMobileOverview.module.scss'
import { ReactComponent as HalfPineappleIcon } from "../../assets/img/icons/pineapple-half-white.svg"; //F6BF4B
import ReactTooltip from 'react-tooltip';
import Avatar from 'react-avatar'
import { getPineapples } from '../../utils/utils'

const NurseMobileOverview = ({data, urlToReviews, showAddReview = false, ...props}) => {
  const { agency, image, rating, total_reviews, name, id } = data;
  const size = 30;
  const floorRating = Math.floor(+rating);
  const decimal = +rating - floorRating;
  const missingPineapple = 5 - Math.ceil(+rating);

  const redirectToReviews = () => {
    window.location = urlToReviews;
  }

  return (
    <div className={`${classes['candidate-card']} `}>

      <div className={`${classes['header']}`}>
        <div className={classes['has-unread']}>
          <div className={classes['has-avatar']}>
            {(!image) ? (
              <Fragment>
                {
                  !name || !name.trim()
                    ? <Avatar
                      round
                      size={size}
                      src="https://s3.us-west-2.amazonaws.com/qa-docs.wanderly.us/profile-photo.svg"
                      color={'#fa8340'}
                    />
                    : <Avatar
                      maxInitials={2}
                      name={name}
                      round
                      size={size}
                      style={{top: '-2px'}}
                    />
                }
              </Fragment>
            ) : (
              <Avatar
                round
                size={size}
                src={image}
                style={{top: '-2px'}}
              />
            )}
          </div>
        </div>

        <div className={classes['candidate-data']}>
          <div className={classes['name']}>{name}</div>
          {
            +rating > 0 && <ReactTooltip
              arrowColor="#A2A5AC"
              backgroundColor="#A2A5AC"
              borderColor="#A2A5AC"
              className={`rc-tooltip ${classes['tooltip']}`}
              effect="solid"
              id={`button-icon-${id}`}
              multiline
              place="top"
            >
              {rating}
            </ReactTooltip>
          }

          <div className={classes['rating-review-icons']} data-for={`button-icon-${id}`} data-tip >
            { +rating > 0 && <div className={classes['rating-review']}>{rating}</div> }
            {
              +rating > 0
                ?
                  <div>
                    {getPineapples(floorRating, true, true)}
                    {
                      decimal > 0 && <HalfPineappleIcon style={{ width: '8px', height: '15px'}} className={classes['pineapple-icon']} />
                    }
                    {getPineapples(missingPineapple, false, false)}
                  </div>
                :
                null
            }
            <div className={`${classes['review-text']} ${total_reviews === 0 ? classes['review-text-no-margin'] : ''}`}>
              {
                total_reviews > 0  ? (<>
                  ({`${total_reviews > 1 ? `${total_reviews} reviews` : `${total_reviews} review`}`})
                </>) : (<>
                  {showAddReview ?
                    <div className="text-center">
                      <button className={classes['button-add-review']} onClick={redirectToReviews}> ADD A REVIEW </button>
                    </div> : '(No reviews yet)'
                  }
                </>)
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NurseMobileOverview;
