import React, {Fragment, useEffect} from 'react';
import {formatDateForTooltips} from "./helpers";
import css from "./Chat.module.scss";


const ChatTime = ({date, isOwnMessage}) => {
  return (
    <div
      className={`${css['message-time']} ${
        isOwnMessage ? css['job-proposal-card-sender'] : ''
      }`}
      title={formatDateForTooltips(date, 'MMM dd, yyyy')}
    >
      {formatDateForTooltips(date, 'MMM dd, yyyy')}
    </div>
  );
}

export default ChatTime;
