import React, {Fragment} from 'react';
import ReactTooltip from 'react-tooltip';

import { getProfession, getTypeJobIcon } from './helpers';

import css from './Chat.module.scss';
import Rate from "../JobProposalRecruiter/Rate";

const JobInfo = ({ job, className, isRecruiterSite = false, isHotLead = false }) => {
  const jobType = +job.job_type;

  return (
    <div className={`${css['job-card']} ${className}`}>
      <div className={css['job-card--info']}>
        <div>
          <div style={{display: "flex", marginBottom: '4px'}}>
            <ReactTooltip
              arrowColor="#7B7B7C"
              backgroundColor="#7B7B7C"
              borderColor="#7B7B7C"
              className={`rc-tooltip ${css['rc-tooltip']}`}
              effect="solid"
              id={`specialties-${job.id}`}
              multiline
            >
              {job.specialities.join(', ')}
            </ReactTooltip>

            <ReactTooltip
              arrowColor="#7B7B7C"
              backgroundColor="#7B7B7C"
              borderColor="#7B7B7C"
              className={`rc-tooltip ${css['rc-tooltip']}`}
              effect="solid"
              id={`profession-${job.id}`}
              multiline
            >
              {getProfession(job.profession_id)}
            </ReactTooltip>
            <div style={{display: "flex", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>
              {getTypeJobIcon(jobType, job.id, '#000000', `${css['job-card--icon']} ${ jobType === 2 && css['permanent']}`)}
              <div className={css['job-card-profession']}  data-for={`profession-${job.id}`} data-tip>
                {getProfession(job.profession_id)}
              </div>
            </div>
            <div
              className={css['job-card-specialties']}
              data-for={`specialties-${job.id}`}
              data-tip
            >
              {job.specialities.map((item, index) => (
                <span key={index}>• {item}</span>
              ))}
            </div>
          </div>

          <ReactTooltip
            arrowColor="#7B7B7C"
            backgroundColor="#7B7B7C"
            borderColor="#7B7B7C"
            className={`rc-tooltip ${css['rc-tooltip']}`}
            effect="solid"
            id={`hospitals-${job.id}`}
            multiline
          >
            {`${job.city ? job.city + (job.state ? ', ' : '') : ''} ${job.state || ''}`}
            {isRecruiterSite && <Fragment> | {job.facility_name} </Fragment>}
          </ReactTooltip>
          <div className={css['job-card-location']} data-for={`hospitals-${job.id}`} data-tip>
            {`${job.city ? job.city + (job.state ? ', ' : '') : ''} ${job.state || ''}`}
            {isRecruiterSite && !isHotLead && <Fragment> | {job.facility_name} </Fragment>}
          </div>
        </div>
      </div>
      <div className={css['job-card--rate']} >
        <div className={css['job-card-price']}> <Rate job={job} /> </div>
      </div>
    </div>
  );
};

export default JobInfo;
