import React from 'react'
import classes from './Checkbox.module.scss'
import _uniqueId from 'lodash/uniqueId'
import PropTypes from 'prop-types'

const Checkbox = ({
  label = '',
  onChange,
  isChecked,
  className,
  disabled = false,
  color = '',
  id,
  value,
  datasetProps = {},
  truncateText,
  isRequired = false,
}) => {
  const idTag = id || _uniqueId('checkbox-')

  const css =
    color === 'yellow'
      ? classes['yellow-checkbox']
      : color === 'green'
      ? classes['green-checkbox']
      : color === 'orange'
      ? classes['orange-checkbox']
      : color === 'blue'
      ? classes['blue-checkbox']
      : classes.checkbox
  return (
    <label
      htmlFor={idTag}
      className={`${css} ${className}`.trim()}
      onClick={(event) => event.stopPropagation()}
    >
      <input
        type='checkbox'
        id={idTag}
        disabled={disabled}
        checked={isChecked}
        onChange={onChange}
        value={value}
        {...datasetProps}
      />
      <span />
      <span className={`${truncateText ? classes['truncate-container'] : ''}`}>
        <span className="legend">{label || ''}</span>
        <span className="required-asterisk">{ isRequired && ('*') }</span>
      </span>
    </label>
  )
}

Checkbox.propTypes = {
  color: PropTypes.oneOf(['yellow', 'orange', 'green', 'blue']),
  onChange: PropTypes.func,
  label: PropTypes.string,
  isChecked: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string
}

Checkbox.defaultProps = {
  color: 'yellow',
  onClick: () => {},
  label: '',
  isChecked: false,
  className: '',
  disabled: false,
  id: ''
}

export default Checkbox
