import React, { useEffect, Fragment } from 'react';
import NumberFormat from 'react-number-format';

import { ReactComponent as EmailIcon } from '../../assets/img/icons/chat/email.svg';
import { ReactComponent as PhoneIcon } from '../../assets/img/icons/icon-phone.svg';
import { ReactComponent as InfoIcon } from '../../assets/img/icons/icon-info.svg';
import {  formatDateForTooltips, getProfession, getTypeJobIcon, getTypeJobText, isOwnMessage } from './helpers';
import css from './Chat.module.scss';

import ChatAvatar from './ChatAvatar';
import PropTypes from "prop-types";
import ChatTime from "./ChatTime";
import {AI_LEAD_FULL_APPLY, AI_LEAD_QUICK_APPLY} from "../../utils/constants";
import ReactTooltip from "react-tooltip";
import Rate from "../JobProposalRecruiter/Rate";
import JobInfo from "./JobInfo";

function ChatContactProfile({ message, identity, details, isAnonymous, isRecruiterSite = false }) {

  const { nurse } = details;
  const { job } = message.attributes;
  const nurseProfile = message.attributes.profile ? message.attributes.profile : nurse;
  const specialties = message.attributes.specialties
    ? message.attributes.specialties
    : nurse.specialities;
  const jobType = +job?.job_type || 1;

  const source = message.attributes.source
    ? message.attributes.source
    : null;

  return (
    <div
      className={`${css['message-single']} ${
        isOwnMessage(message, identity, isRecruiterSite) ? css.receiver : css.sender
      }`}
    >
      <div className={css['message-bubble-container']}>
        <div className={`col-auto ${isOwnMessage(message, identity, isRecruiterSite) ? 'ms-2' : 'me-2'}`}>
          <ChatAvatar  message={message} identity={identity} details={details} isAnonymous={isAnonymous} isRecruiterSite={isRecruiterSite} />
        </div>
        <div className="col">
          <div className={`${css['chat-contact-card-wrapper']} ${
            isRecruiterSite ? css['chat-contact-card-wrapper-sender'] : css['chat-contact-card-wrapper-receiver']
          }`}>
            <div className={`${css['chat-contact-card-title']} ${(isRecruiterSite && (+source === AI_LEAD_FULL_APPLY || +source === AI_LEAD_QUICK_APPLY)) ? css['hot-lead'] : ''}`}>
              {(isRecruiterSite && (+source === AI_LEAD_FULL_APPLY || +source === AI_LEAD_QUICK_APPLY)) && 'Hot Lead Alert'}
              {(!isRecruiterSite || (+source !== AI_LEAD_FULL_APPLY && +source !== AI_LEAD_QUICK_APPLY)) && 'Contact Information' }
            </div>
            {(isRecruiterSite && (+source === AI_LEAD_FULL_APPLY || +source === AI_LEAD_QUICK_APPLY))
              && (<div className={css['hot-lead-legend']}>
                <span> <InfoIcon fill={'#9D124A'}  />  A Wanderly clinician is looking for the same jobs you have posted, They have not applied yet, but they're actively looking. Now is your time to jump in. Contact them today! </span>
              </div>)}

            {job && <JobInfo job={job} className={css['quick-app-card--job']} isRecruiterSite={isRecruiterSite} isHotLead={true} /> }

            <div className={`${css['chat-contact-card']}`}>
              <span className={`${css['recruiter-name']}`}>
                {nurseProfile.first_name} {nurseProfile.last_name}
              </span>
              <div className={`${css.specialtiesList}`}>
                <span className={`${css.profession}`}>{nurseProfile.profession_type}</span>
                {specialties && Array.isArray(specialties) && specialties.map((speciality, i) => (
                  <span
                    key={`contact-profile-specialty${i}`}
                    className={`${css['speciality-text']}`}
                  >
                    {typeof speciality === 'object' ? speciality.name : speciality}
                  </span>
                ))}
              </div>
              <span className={`${css['recruiter-contact-info']}`}>
                <EmailIcon className="me-2" fill="#303030" width={14} />
                {nurseProfile.email}
              </span>
              <span className={`${css['recruiter-contact-info']}`}>
                <PhoneIcon className="me-2" fill="#303030" width={14} />
                <span className={`${css.nurse_home_phone}`}>
                  <NumberFormat
                    displayType="text"
                    format="(###)###-####"
                    value={nurseProfile.home_phone_number}
                  />
                </span>
              </span>
            </div>
          </div>
          <ChatTime isOwnMessage={isOwnMessage(message, identity, isRecruiterSite)} date={message.dateUpdated} />
        </div>
      </div>
    </div>
  );
}

ChatContactProfile.propTypes = {
  message: PropTypes.object.isRequired,
  identity: PropTypes.number.isRequired,
  details: PropTypes.object.isRequired,
  isAnonymous: PropTypes.bool,
};

ChatContactProfile.defaultProps = {
  message: {},
  identity: 0,
  details: {},
  isAnonymous: false
};

export default ChatContactProfile;
