import React, { useState } from 'react';
import AccountToggle from '../AccountToggle/AccountToggle';
import classes from './Navigation.module.scss';
import PropTypes from "prop-types";

const Navigation = ({ navbarBrand, navStyle, menuElements, accountToggleElements, accountToggleProps, isImpersonated = false, classname = '', account = '' }) => {
  const navMenuType = navStyle === 'recruiter' ? classes.recruiter
    : navStyle === 'agency' ? classes.agency
    : navStyle === 'nurse' ? classes.nurse : '';

  const impersonatedClass = isImpersonated ? classes.impersonated : '';

  return (
    <nav className={`${classes.navbar} navbar navbar-expand-lg navbar-light bg-light ${classname} ${navMenuType} ${impersonatedClass}`.trim()}>
      <div className={`${classes['navbar-container']} navbar-container`}>
        <div className={`${classes['navbar-brand']} navbar-brand`}>{navbarBrand}</div>

        <div className={`${classes['navbar-menu']} navbar-menu`}>
          <div>
            <ul className={(classes['navbar-nav']) + ' navbar-nav mb-2 mb-lg-0'}>{menuElements}</ul>
          </div>
        </div>

        <div className={`${classes['navbar-avatar']} d-flex`}>
            <AccountToggle {...accountToggleProps} account={account}>{accountToggleElements}</AccountToggle>
          </div>
      </div>
    </nav>
  )
}

Navigation.propTypes = {
  navbarBrand: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  navStyle: PropTypes.oneOf(['recruiter', 'agency', 'nurse']),
  menuElements: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  accountToggleElements: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  accountToggleProps: PropTypes.object,
  isImpersonated: PropTypes.bool
};

Navigation.defaultProps = {
  isImpersonated: false,
};

export default Navigation
