import React, {Fragment} from "react";
import { ReactComponent as PineappleIcon } from '../assets/img/icons/pineapple.svg'
import classes from '../components/RecruiterOverview/RecruiterOverview.module.scss'
import isToday from "date-fns/isToday";
import format from "date-fns/format";
import isYesterday from "date-fns/isYesterday";
import {getPaymentTypeAbbr} from "../components/Chat/helpers";

export const humanize = (arr) => {
  if (!arr) {
    return "";
  }

  if (!Array.isArray(arr)) {
    return arr.replace('_', ' ');
  }

  if (arr.length === 0) {
    return "";
  }

  if (!arr[0]) {
    return "";
  }

  let i, frags = arr[0].split('_');
  for (i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }

  return frags.join(' ');
}

export const formatDateFns = (dateFmt) => {
  if (!dateFmt) {
    return "";
  }

  const dateNew = new Date(dateFmt.replace(/ /g,"T"));
  let labelDate = '';

  if (isToday(dateNew)) {
    labelDate = format(dateNew, 'hh:mm a');
  } else if (isYesterday(dateNew)) {
    labelDate = `Yesterday`;
  } else {
    labelDate = format(dateNew, 'MMM dd, yyyy')
  }

  return labelDate;
}

export const formatDate = (dateFmt) => {
  if (!dateFmt) {
    return "";
  }

  const date = new Date(dateFmt.replace(/ /g,"T"));
  const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
  const month = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
  const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);

  return `${month} ${day}, ${year}`;
}

export const formatPhone = (phone) => {
  const cleaned = ('' + phone).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = (match[1] ? '+1 ' : '');
    return [intlCode, match[2], '-', match[3], '-', match[4]].join('');
  }
  return phone;
}

export const capitalize = ( str ) => {
  if (!str) {
    return "";
  }
  return str.charAt( 0 ).toUpperCase() + str.slice( 1 );
};

export const formatList = (list) => {
  if (!list) {
    return null;
  }
  if (!Array.isArray(list)) {
    return list;
  }
  return list.join(", ")
}

export const getRate = (job, isHourly = false) => {
  return +job.job_type === 1
    ? parseInt(job.payment_max).toFixed() + '/' + getPaymentTypeAbbr(job.payment_type)
    :
    isHourly ? job.payment_min.toFixed(2) + (job.payment_max > 0 ? ' - ' + job.payment_max.toFixed(2) : '') + '/' + getPaymentTypeAbbr(job.payment_type)
      : parseInt(job.payment_min).toFixed() + (job.payment_max > 0 ? ' - ' + parseInt(job.payment_max).toFixed() : '') + '/' + getPaymentTypeAbbr(job.payment_type);
}

export const getRateV2 = (job) => {
  const minPayment = +job.payment_min;
  const maxPayment = +job.payment_max;
  const jobType = +job.job_type;

  if (job.hide_pay_rate) {
    return 'Contact recruiter for more details';
  }

  return jobType === 2 ? (  `$${minPayment.toFixed()} - ${maxPayment.toFixed()} ${isHourly(job.permanent_job_salary_type)  ? 'hr':  'yr' }`) :  `$${maxPayment.toFixed()}/wk`;
}


export const isHourly = (salary_type) => {
  return salary_type === "hourly";
}

/*export const getPaymentTypeAbbr = (salary_type) => {
  return isHourly(salary_type) ? 'hr' : 'yr';
}*/

export const getPineapples = (control, full, isWhite= false) => {
  const fullPineapples = [];
  for (let i = 0; i < control; i++) {
    fullPineapples.push(full ? <PineappleIcon key={'full_pineappleicon_'+i} fill={`${isWhite ? '#fff' : '#F6BF4B' }`} style={{ width: '8px', height: '15px',  marginRight: '2px'}} /> : <PineappleIcon key={'full_pineappleicon_'+i} fill={`${isWhite ? '#fff' : '#BDBDBD' }`} style={{ width: '8px', height: '15px', marginRight: '2px'}} className={classes['pineapple-icon']} />);
  }

  return fullPineapples;
}

export const getEmptyPineapples = () => {
  const emptyPineapples = [];
  for (let i = 0; i < 5; i++) {
    emptyPineapples.push(<PineappleIcon key={'empty_pineappleicon_'+i} fill='#BDBDBD' style={{ width: '8px', height: '15px', marginRight: '2px'}} className={classes['pineapple-icon']} />);
  }

  return emptyPineapples;
}

export const getFileExtension = (name) =>
  name ? `${name.split('.').pop()}` : null;

export const DOCUMENT_IDENTIFICATION_NAMES = {
  driver_license: 'Driver License',
  social_security_card: 'Social Security Card',
  passport: 'Passport',
  birth_certificate: 'Birth Certificate',
};

export const getLicenseName = (license) => {
  const { name, thestate, license_name, issued_by } = license;

  let nameParts = [];

  if (name.toLowerCase() === 'rn license') {
    nameParts = ['RN'];

    if (thestate) {
      nameParts.push(thestate.name);
    }
  } else if (name.toLowerCase() === 'lvn/lpn license') {
    nameParts = ['LVN/LPN'];

    if (thestate) {
      nameParts.push(thestate.name);
    }
  } else if (name.toLowerCase() === 'other') {
    nameParts = [license_name, issued_by];
  } else {
    nameParts = [name, issued_by];
  }

  return nameParts.join(' ');
};

export const getFileName = (doc, filename) => {
  if (!doc.name) {
    return filename;
  }

  return doc.name;
};

export const getDocumentUrls = (document, docName, baseUrl) => {
  const documentsToDownload = [];
  const notBackAndFront = ['IDENTIFICATION', 'MEDICAL', 'OTHER'];

  if (document.filename) {
    // This one is used for both, old and new profiles, for new will download generated PDF
    const filename = `${
      baseUrl + encodeURIComponent(document.filename)
    }&realname=${encodeURIComponent(getFileName(document, docName))}${
      notBackAndFront.includes(document.type) ? '' : '-front'
    }`;

    documentsToDownload.push(filename);
  }

  if (document.filename_back) {
    // This one is used for old sent profiles
    const filenameBack = `${
      baseUrl + encodeURIComponent(document.filename_back)
    }&realname=${encodeURIComponent(getFileName(document, docName))}-back`;

    documentsToDownload.push(filenameBack);
  }

  const filenameFrontPDF = `${
    baseUrl + encodeURIComponent(document.pdfurl)
  }&realname=${encodeURIComponent(getFileName(document, docName))}`;

  documentsToDownload.push(filenameFrontPDF);

  return documentsToDownload;
};

export const downloadFile = (url) => {
  const iframe = document.createElement('iframe');
  iframe.style.visibility = 'collapse';
  document.body.append(iframe);

  // Get the query string starting after ?
  const querystring = url.split('?');
  let input = '';

  if (querystring[1]) {
    // Construct the query string object
    const queryObj = JSON.parse(
      `{"${decodeURI(
        querystring[1].replace(/&/g, '","').replace(/=/g, '":"')
      )}"}`
    );
    input = `<input type="hidden" name="filename" value="${queryObj.filename}"/>
                    <input type="hidden" name="realname" value="${queryObj.realname}"/>`;
  }

  iframe.contentDocument.write(
    `<form action="${url}" method="GET">
                    ${input}
                </form>`
  );
  iframe.contentDocument.forms[0].submit();
  setTimeout(() => iframe.remove(), 10000);
};

export const countProperties = (obj) => Object.keys(obj).length;

// Extend the default Number object with a formatMoney() method:
// usage: someVar.formatMoney(decimalPlaces, symbol, thousandsSeparator, decimalSeparator)
// defaults: (2, "$", ",", ".")
export const formatMoney = function(number, places, symbol, thousand, decimal) {
  places = !isNaN(places = Math.abs(places)) ? places : 2;
  symbol = symbol !== undefined ? symbol : "$";
  thousand = thousand || ",";
  decimal = decimal || ".";
  var negative = number < 0 ? "-" : "",
    i = parseInt(number = Math.abs(+number || 0).toFixed(places), 10) + "",
    j = (j = i.length) > 3 ? j % 3 : 0;
  return symbol + negative + (j ? i.substr(0, j) + thousand : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousand) + (places ? decimal + Math.abs(number - i).toFixed(places).slice(2) : "");
};


export const getExperienceLevel = (expLevel) => {
  const config = {
    1: 'Entry Level',
    2: 'Residency Program',
    3: 'Experienced',
    4: 'Leadership'
  }

  return config[+expLevel] || false;
}

/**
 * Capitalizes first letters of words in string.
 * @param {string} str String to be modified
 * @param {boolean=false} lower Whether all other letters should be lowercased
 * @return {string}
 * @usage
 *   capitalize('fix this string');     // -> 'Fix This String'
 *   capitalize('javaSCrIPT');          // -> 'JavaSCrIPT'
 *   capitalize('javaSCrIPT', true);    // -> 'Javascript'
 */
export const capitalizeAll = (str, lower = false) => (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());

/**
 *
 * @param amount
 * @param decimals
 * @returns {number}
 */
export const formatPayRate = (amount, decimals = 2) => {
  const parseAmount = +amount;

  if (!parseAmount || isNaN(parseAmount)) {
    return 0;
  }

  return parseFloat(parseAmount.toFixed(decimals));
}

export const getAssignmentLength = (job) => {
  return (
    <Fragment>
      <>
        {job.assignment_length > 0 ? (
          <Fragment>
            {job.assignment_length}
            {job.assignment_length_type === 2 ? (
              <Fragment>{job.assignment_length > 1 ? ' days' : ' day'}</Fragment>
            ) : (
              <>{job.assignment_length > 1 ? ' weeks' : ' week'}</>
            )}
          </Fragment>
        ) : (
          'Ongoing'
        )}
      </>
    </Fragment>
  );
};

export const calculateLocalWeeklyHoursBreakdown = (jobData) => {
  const weekly_hours = +jobData.hours_per_shift * +jobData.shifts_per_week;

  const weekly_ot = +jobData?.state_dependant_data?.weeklyot;
  const daily_ot = jobData?.state_dependant_data?.dailyot;

  // Weekly regular hours
  let weekly_regular_hours = Math.min(weekly_hours, weekly_ot);

  // Calculate Regular hours for the States having specific regular hours per day
  // For instance CA, AK, PR (8 hours), CO (12 hours)
  if (jobData?.hours_per_shift > daily_ot && (daily_ot === 8 || daily_ot === 12)) {
    weekly_regular_hours = daily_ot * +jobData?.shifts_per_week;
  }

  // Weekly OT Hours
  const weekly_ot_hours = weekly_hours > weekly_regular_hours ? weekly_hours - weekly_regular_hours : 0;

  return {
    'weekly_regular_hours': weekly_regular_hours,
    'weekly_ot_hours': weekly_ot_hours,
  };
}

export const getLocalTotalTaxableHourly = (jobData) => {
  const hours_per_week = +jobData?.hours_per_shift * +jobData?.shifts_per_week;
  const minTotalTaxableHourly = +jobData?.min_blended_payrate / hours_per_week;

  let total_taxable_hourly = `$${formatPayRate(minTotalTaxableHourly)}`;
  if (+jobData?.max_blended_payrate ) {
    total_taxable_hourly += ` - $${formatPayRate(+jobData?.max_blended_payrate / hours_per_week)} `;
  }

  return total_taxable_hourly;
}

export const getEmploymentType = (employmentType) => {
  const config = {
    1: 'Full Time',
    2: 'Part Time',
    3: 'PRN'
  }

  return config[+employmentType] || '';
}

export const getShiftType = (job) => {
  const { shift_type, job_type } = job;
  let shiftTypeName = '';

  if (shift_type === '0') {
    return shiftTypeName;
  }

  if (job_type === '4' || job_type === '5') {
    if (shift_type === '1') {
      shiftTypeName = 'Reg ';
    } else {
      shiftTypeName = 'Wknd ';
    }
  }

  return shiftTypeName;
}
