import React, { Fragment, useEffect, useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import Avatar from 'react-avatar'
import { ReactComponent as TrashIcon } from "../../assets/img/icons/icon-trash.svg";
import { ReactComponent as PineappleIcon } from "../../assets/img/icons/pineapple.svg";
import { ReactComponent as HalfPineappleIcon } from "../../assets/img/icons/pineapple-half.svg"; //F6BF4B
import { ReactComponent as InboxIcon } from "../../assets/img/icons/letter-icon.svg";

import { getPineapples  } from '../../utils/utils'
import css from "./ChatHeader.module.scss";
import PropTypes from "prop-types";

const ChatHeader = ({job = {}, rating= 0, reviews = 0,  allowToAddReview = false, onClickConversation, typeMessage, urlToReviews} ) => {
  const {id, recruiter, recruiter_id, agency} = job;

  const size = 33;
  const floorRating = Math.floor(+rating);
  const decimal = +rating - floorRating;
  const missingPineapple = 5 - Math.ceil(+rating);

  const redirectToReviews = () => {
    window.location = urlToReviews;
  }

  return (
    <div className={`${css["chat-header"]}`}>
      <div className={css['recruiter-image']}>
        {(!recruiter.image) ? (
          <Fragment>
            {
              !recruiter.full_name || !recruiter.full_name.trim()
                ? <Avatar
                  round
                  size={size}
                  src="https://s3.us-west-2.amazonaws.com/qa-docs.wanderly.us/profile-photo.svg"
                  color={'#fa8340'}
                />
                : <Avatar
                  maxInitials={2}
                  name={recruiter.full_name}
                  round
                  size={size}
                  style={{top: '-2px'}}
                />
            }
          </Fragment>
        ) : (
          <Avatar
            round
            size={size}
            src={recruiter.image}
            style={{top: '-2px'}}
          />
        )}
      </div>

      <div className={css['recruiter-info']}>
        <div className={css['recruiter-info-name']}> {recruiter.full_name} </div>

        <div className={css['recruiter-info-reviews']}>
          {
            +rating > 0 && <ReactTooltip
              arrowColor="#A2A5AC"
              backgroundColor="#A2A5AC"
              borderColor="#A2A5AC"
              className={`rc-tooltip ${css['rc-tooltip']}`}
              effect="solid"
              id={`button-icon-${id}`}
              multiline
              place="top"
            >
              {rating}
            </ReactTooltip>
          }

          <div className={css['rating-reviews']}>
            {+rating > 0 && <div className={css['rating-reviews-tag']}>{rating}</div> }
            <div className={css['rating-reviews-icon']} data-for={`button-icon-${id}`} data-tip style={+rating > 0 ? {} : {display: 'none'}}>
              {
                +rating > 0
                  ?
                  <div>
                    {getPineapples(floorRating, true)}
                    {
                      decimal > 0 && <HalfPineappleIcon style={{ width: '8px', height: '15px',  marginRight: '2px'}} className={css['pineapple-icon']} />
                    }
                    {getPineapples(missingPineapple, false, false)}
                  </div>
                  :
                  <div>
                    <PineappleIcon fill='#BDBDBD' className={css['pineapple-icon']} />
                    <PineappleIcon fill='#BDBDBD' className={css['pineapple-icon']} />
                    <PineappleIcon fill='#BDBDBD' className={css['pineapple-icon']} />
                    <PineappleIcon fill='#BDBDBD' className={css['pineapple-icon']} />
                    <PineappleIcon fill='#BDBDBD' className={css['pineapple-icon']} />
                  </div>
              }
            </div>
            <div className={css['rating-reviews-text']}>
              {
                reviews > 0  ? (<>
                  ({`${reviews > 1 ? `${reviews} reviews` : `${reviews} review`}`})
                </>) : (<>
                  {allowToAddReview ?
                    <div className="text-center">
                      <button className={css['button-add-review']} onClick={redirectToReviews}> ADD A REVIEW </button>
                    </div> : '(No reviews yet)'
                  }
                </>)
              }
            </div>
          </div>
        </div>
      </div>

      <div className={css['delete-container']}>
        {typeMessage === 'all_messages' && (
          <button onClick={onClickConversation}>
            <TrashIcon fill="#A09F9D" style={{marginRight: '4px'}}/> Delete
          </button>
        )}

        {typeMessage === 'deleted' && (
          <button onClick={onClickConversation}>
            <InboxIcon fill="#A09F9D" style={{marginRight: '4px'}}/> Move to inbox
          </button>
        )}
      </div>
    </div>
  );
};

ChatHeader.propTypes = {
  job: PropTypes.object.isRequired,
  rating: PropTypes.number,
  reviews: PropTypes.number,
  allowToAddReview: PropTypes.bool,
};

ChatHeader.defaultProps = {
  job: {},
  rating: 0,
  reviews: 0,
  allowToAddReview: false,
};

export default ChatHeader;
