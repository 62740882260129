import React from 'react';

import { formatDateForTooltips, isOwnMessage } from './helpers';
import QuickApplicationMessage from './QuickApplicationMessage';
import css from './Chat.module.scss';

import ChatAvatar from './ChatAvatar';
import PropTypes from "prop-types";
import ChatTime from "./ChatTime";

function ChatQuickApplication({ message, identity, details, isAnonymous, isRecruiterSite = false, onClick = () => {}  }) {
  const { nurse, job } = details;
  const nurseProfile = message.attributes.profile ? message.attributes.profile : nurse;
  const ownMessage = isOwnMessage(message, identity, isRecruiterSite);
  const attributes = message.attributes;

  return (
    <div
      className={`${css['message-single']} ${
        !isRecruiterSite ? css.receiver : css.sender
      }`}
    >
      <div className={css['message-bubble-container']}>
        <div className={`col-auto ${ownMessage ? 'ms-2' : 'me-2'}`}>
          <ChatAvatar message={message} identity={identity} details={details} isAnonymous={isAnonymous} isRecruiterSite={isRecruiterSite}  />
        </div>
        <div className="col">
          <QuickApplicationMessage job={job} ownMessage={ownMessage} isRecruiterSite={isRecruiterSite} onClick={onClick} attributes={attributes} nurse={nurse} />
          <ChatTime date={message.dateUpdated} isOwnMessage={isOwnMessage(message, identity, isRecruiterSite)} />
        </div>
      </div>
    </div>
  );
}

ChatQuickApplication.propTypes = {
  message: PropTypes.object.isRequired,
  identity: PropTypes.number.isRequired,
  details: PropTypes.object.isRequired,
  isAnonymous: PropTypes.bool,
};

ChatQuickApplication.defaultProps = {
  message: {},
  identity: 0,
  details: {},
  isAnonymous: false
};

export default ChatQuickApplication;
