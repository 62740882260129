import React from 'react';

import { isOwnMessage } from './helpers';

import css from './Chat.module.scss';

import ChatAvatar from './ChatAvatar';
import PropTypes from "prop-types";
import ChatTime from "./ChatTime";

function ChatMessage({ message, identity, details, isAnonymous, isRecruiterSite = false } ) {
  const { viaAutoReply, viaVR } = message.attributes;

  return (
    <div
      className={`${css['message-single']} ${
        isOwnMessage(message, identity, isRecruiterSite) ? css.receiver : css.sender
      } ${viaVR ? css.viaVR : ''}`}
    >
      <div className={css['message-bubble-container']}>
        <div className={`col-auto ${isOwnMessage(message, identity, isRecruiterSite) ? 'ms-2' : 'me-2'}`}>
          <ChatAvatar message={message} identity={identity} details={details} isAnonymous={isAnonymous} isRecruiterSite={isRecruiterSite}  />
        </div>
        <div className="col">
          {viaAutoReply ? (
            <span
              className={css.message}
              dangerouslySetInnerHTML={{
                __html: message.body,
              }}
            />
          ) : (
            <span className={css.message}>{message.body}</span>
          )}
          <ChatTime date={message.dateUpdated} isOwnMessage={isOwnMessage(message, identity, isRecruiterSite)} />
        </div>
      </div>
    </div>
  );
}

ChatMessage.propTypes = {
  message: PropTypes.object.isRequired,
  identity: PropTypes.number.isRequired,
  details: PropTypes.object.isRequired,
  isAnonymous: PropTypes.bool,
};

ChatMessage.defaultProps = {
  message: {},
  identity: 0,
  details: {},
  isAnonymous: false
};

export default ChatMessage;
