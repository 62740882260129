import React, {Fragment, useState} from 'react';

import { ReactComponent as GreenCheckmarkIcon } from '../../assets/img/icons/green-checkmark.svg';
import {
  formatDateForTooltips,
  getCertificationName,
  getLicenseName,
  isOwnMessage,
} from './helpers';
import css from './Chat.module.scss';

import ChatAvatar from './ChatAvatar';
import JobInfo from './JobInfo';
import PropTypes from "prop-types";
import ChatTime from "./ChatTime";

function ChatFullApplication( { message, identity = 0 , details, isAnonymous = false, isRecruiterSite = false, onClick = () => {} } ) {
  const [showOtherDocuments, setShowOtherDocuments] = useState(false);
  const { nurse, job } = details;

  if (!job) {
    return false
  }

  const attributes = message.attributes;

  return (
    <div
      className={`${css['message-single']} ${
        isOwnMessage(message, identity, isRecruiterSite) ? css.receiver : css.sender
      }`}
    >
      <div className={css['message-bubble-container']}>
        <div className={`col-auto ${isOwnMessage(message, identity, isRecruiterSite) ? 'ms-2' : 'me-2'}`}>
          <ChatAvatar message={message} identity={identity} details={details} isAnonymous={isAnonymous} isRecruiterSite={isRecruiterSite} />
        </div>
        <div className="col">
          <div className={`${css['quick-app-card']} ${isRecruiterSite ? '' : css['own-message']}`} onClick={() => {onClick(attributes.application_id, 'application')}}>
            <div className={css['quick-app-card--title']}>{isRecruiterSite ? 'Application' : 'Quick Application'}</div>
            <div className={css['quick-app-card--documents']}>
              <JobInfo job={job} className={css['quick-app-card--job']} isRecruiterSite={isRecruiterSite} />
            </div>
          </div>
          <ChatTime isOwnMessage={isOwnMessage(message, identity, isRecruiterSite)} date={message.dateUpdated} />
        </div>
      </div>
    </div>
  );
}

ChatFullApplication.propTypes = {
  message: PropTypes.object.isRequired,
  identity: PropTypes.number.isRequired,
  details: PropTypes.object.isRequired,
  isAnonymous: PropTypes.bool,
};

ChatFullApplication.defaultProps = {
  message: {},
  identity: 0,
  details: {},
  isAnonymous: false
};

export default ChatFullApplication;
