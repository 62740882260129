import Button from '../Button/Button';
import React, {Fragment} from 'react';

import { ReactComponent as PhoneIcon } from '../../assets/img/icons/icon-phone.svg';
import { formatDateForTooltips, isOwnMessage } from './helpers';
import css from './Chat.module.scss';

import ChatAvatar from './ChatAvatar';
import PropTypes from "prop-types";
import ChatTime from "./ChatTime";
import {capitalizeAll} from "../../utils/utils";

function ChatContactRequest({ message, identity, details, sendContactInfo, declineAction, isAnonymous, statusRequestContactInfo, isRecruiterSite = false }) {
  const { recruiter, oldContact } = details;

  const recruiterName = recruiter.full_name || `${recruiter.firstname} ${recruiter.lastname}`

  return (
    <div
      className={`${css['message-single']} ${
        isOwnMessage(message, identity, isRecruiterSite) ? css.receiver : css.sender
      }`}
    >
      <div className={css['message-bubble-container']}>
        <div className={`col-auto ${isOwnMessage(message, identity, isRecruiterSite) ? 'ms-2' : 'me-2'}`}>
          <ChatAvatar message={message} identity={identity} details={details} isAnonymous={isAnonymous} isRecruiterSite={isRecruiterSite}  />
        </div>
        <div className="col">
          <div className={css['contact-info-req-card']}>
            <span className={css['icon-wrapper']}>
              <PhoneIcon fill="#303030" width={33} />
            </span>
            <div className={css['request-box-text']}>
              <Fragment>
                <p
                  className={`${css['contact-info-text']} ${css['requested-contact-text']}`}
                >
                  {capitalizeAll(recruiterName, true)} is requesting your contact
                  information.<br/>Would you like to share?
                </p>
                <Button className={css['yellow-button']} onClick={sendContactInfo}>
                  Send My Contact Info
                </Button>

                <Button className={css['text-button']} onClick={declineAction}>
                  Decline
                </Button>
              </Fragment>
            </div>
          </div>
          <ChatTime isOwnMessage={isOwnMessage(message, identity, isRecruiterSite)} date={message.dateUpdated} />
        </div>
      </div>
    </div>
  );
}

ChatContactRequest.propTypes = {
  message: PropTypes.object.isRequired,
  identity: PropTypes.number.isRequired,
  details: PropTypes.object.isRequired,
  isAnonymous: PropTypes.bool,
  sendContactInfo: PropTypes.func.isRequired,
  declineAction: PropTypes.func.isRequired,
};

ChatContactRequest.defaultProps = {
  message: {},
  identity: 0,
  details: {},
  isAnonymous: false
};

export default ChatContactRequest;
